// Indicator

.indicator {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    right: 0.18rem;
    top: 0rem;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    border: 2px solid $white;
  }
}
.indicator-primary.indicator {
  &::before {
    background-color: $primary;
  }
}
