//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;
.hero-txt h1 {
  // font-size: 4vw;
  font-size: 9.7vmin;
}

.hero-txt p {
  font-size: 4vmin;
  text-align: justify;
}

.justify {
  text-align: justify;
}

.loading {
  bottom: 0;
  left: 45%;
  max-width: 100px;
  position: absolute;
}

.hide {
  display: none;
  transition: ease 0.4s;
}
.show {
  display: block;
  transition: ease 0.4s;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.captchaError {
  border: solid;
  border-color: red;
}

.justify {
  text-transform: justify;
}

.error {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #18113c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e7ed;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: #e53f3c;
  padding-right: calc(1.6em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e53f3c%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e53f3c%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.375rem) center;
  background-size: calc(0.8em + 0.75rem) calc(0.8em + 0.75rem);
}

.clearError {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #18113c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e8e7ed;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  border-color: primary;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:active {
  border-color: primary;
}

.validationError {
  color: #e53f3c;
}

.pointer {
  cursor: pointer;
}

.hide {
  top: -100px;
  transition: ease 0.7s;
}
.show {
  top: 0px;
  transition: ease 0.5s;
}

.navOptions {
  position: fixed;
}

.corners {
  border-radius: 0.25rem;
}

.pop {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fitter {
  max-width: 100%;
}

.nladj {
  color: $dark;
  font-size: 1.15rem;
  font-weight: 500;
  &:focus {
    color: $secondary;
  }
}

.dropdown-toggle {
  border-bottom: none !important;
}

.bg-infinite {
  text-align: center;
  /* img size is 50x50 */
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC")
    repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 0.92s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 0.92s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 0.92s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse 0.92s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.bg-grey-sq {
  background: url(../../assets/images/background/random_grey_variations.png)
    repeat 0 0;
}

// Animated lines bg
.animatedLinesBGContainer {
  position: relative; /* Ensure the container is positioned relative */
  overflow: hidden;
}

.animatedLinesBG {
  background: radial-gradient($dark-secondary, $dark-primary);
  overflow-y: hidden;
  animation: fadeIn 1 1s ease-out;
}

.light {
  position: absolute;
  width: 0px;
  opacity: 0.75;
  background-color: $primary;
  box-shadow: #e9f1f1 0px 0px 20px 2px;
  opacity: 0;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.moonpay {
  position: relative; /* or use position: absolute; depending on your layout */
  z-index: 2; /* Ensure the MoonPay widget is above the lines */
}

.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0%;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@-moz-keyframes floatUp {
  0% {
    top: 100%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0%;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@-o-keyframes floatUp {
  0% {
    top: 100%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0%;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@keyframes floatUp {
  0% {
    top: 100%;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0%;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}

/* Cool infinite background scrolling animation.*/

@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  mask-image: linear-gradient(transparent 20%, rgba(0, 0, 0, 0.8) 100%);
}
