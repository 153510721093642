// Footer

.footer {
  color: $gray-700;

}

.nav-footer {
  .nav-link {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $gray-700;
    padding: 0.2rem 1rem;
    &:hover {
      color: $primary;
    }
  }
}
